import React, { useEffect, useState } from 'react';
import { Text } from 'spoton-lib';
import { isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit';
import { LoadOptions, Response } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';

import Styles from './Location.module.scss';
import { useAppDispatch, useAppSelector } from 'api/store';
import { StoreCandidates } from '../../MerchantOnboarding.types';
import * as merchantOnboardingSlice from '../../MerchantOnboarding.slice';
import { LocationError } from './LocationError';
import { PaginateDropdown, ReusableModal } from 'features/common';

interface ILocation {
    isSyncLocationModalOpen: boolean;
    setIsSyncLocationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onSyncLocationConfirm: (selectedLocation: StoreCandidates) => void;
    setIsSessionExpired: React.Dispatch<React.SetStateAction<boolean>>;
}

export type Option = {
    label: string;
    value: StoreCandidates;
    isDisabled: boolean;
};

type Additional = {
    page: number;
};

export function Location(props: ILocation) {
    const {
        isSyncLocationModalOpen,
        setIsSyncLocationModalOpen,
        onSyncLocationConfirm,
        setIsSessionExpired,
    } = props;

    const dispatch = useAppDispatch();
    const {
        businessLocationId,
        exchangeCode,
        locationErrorMessage,
        providerStoreName,
        providerAddress,
        selectedDdStoreDetails,
    } = useAppSelector((state) => state.merchantOnboarding);
    const [selectedLocation, setSelectedLocation] = useState<Option | null>(
        null,
    );

    const onConfirm = () => {
        if (selectedLocation) {
            onSyncLocationConfirm(selectedLocation.value);
            setSelectedLocation(null);
        }
    };

    const onSave = async () => {
        if (businessLocationId && selectedLocation) {
            const response = await dispatch(
                merchantOnboardingSlice.saveSelectedLocation({
                    location_id: businessLocationId,
                    storeDetails: selectedLocation.value,
                }),
            );
            if (response && isFulfilled(response)) {
                if (response.payload.status === 'success') {
                    await dispatch(
                        merchantOnboardingSlice.getStoreIntegrationStatus({
                            businessLocationId,
                        }),
                    );
                    setIsSyncLocationModalOpen(false);
                }
            }
        }
    };

    const loadOptions: LoadOptions<
        any,
        GroupBase<any>,
        Additional | undefined
    > = async (
        searchQuery,
        loadedOptions,
        { page }: any,
    ): Promise<Response<any, GroupBase<any>, Additional | undefined>> => {
        if (businessLocationId && exchangeCode && !locationErrorMessage) {
            const pageSize = 50;
            const storeListRes = await dispatch(
                merchantOnboardingSlice.getStoreList({
                    businessLocationId,
                    exchange_code: exchangeCode,
                    pageIndex: page,
                    pageSize,
                }),
            );
            if (storeListRes && isFulfilled(storeListRes)) {
                const storesList =
                    storeListRes.payload.data.store_candidates.map((store) => ({
                        value: store,
                        label: store.name,
                        isDisabled: !store.is_eligible_to_onboard,
                    }));
                return {
                    options: storesList,
                    hasMore:
                        Math.ceil(
                            storeListRes.payload.data.total_count / pageSize,
                        ) > page,
                    additional: {
                        page: page + 1,
                    },
                };
            }

            if (storeListRes && isRejectedWithValue(storeListRes)) {
                if (storeListRes.payload?.errors.statusCode === 440) {
                    setIsSessionExpired(true);
                }
            }
        }
        return {
            options: [],
            hasMore: false,
        };
    };

    useEffect(() => {
        if (selectedDdStoreDetails && isSyncLocationModalOpen) {
            if (
                selectedDdStoreDetails.name &&
                selectedDdStoreDetails.lat &&
                selectedDdStoreDetails.is_active_on_integration !== undefined &&
                selectedDdStoreDetails.is_eligible_to_onboard !== undefined &&
                selectedDdStoreDetails.lng
            ) {
                setSelectedLocation({
                    label: selectedDdStoreDetails.name,
                    isDisabled: !selectedDdStoreDetails.is_eligible_to_onboard,
                    value: {
                        ...selectedDdStoreDetails,
                        lat: selectedDdStoreDetails.lat,
                        lng: selectedDdStoreDetails.lng,
                        is_active_on_integration:
                            selectedDdStoreDetails.is_active_on_integration,
                        is_eligible_to_onboard:
                            selectedDdStoreDetails.is_eligible_to_onboard,
                    },
                });
            } else {
                setSelectedLocation(null);
            }
        }
    }, [selectedDdStoreDetails, isSyncLocationModalOpen]);

    return (
        <ReusableModal
            isOpen={isSyncLocationModalOpen}
            title="Connect your DoorDash location to SpotOn"
            onCancel={() => {
                setIsSyncLocationModalOpen(false);
                setSelectedLocation(null);
            }}
            onConfirm={onConfirm}
            secondaryConfirmButtonText="Save & Close"
            onClickSecondaryConfirmButton={onSave}
            isFooterBtnsDisabled={
                locationErrorMessage || !selectedLocation ? true : false
            }
            showCancelBtn={false}
            gap={providerAddress || providerStoreName ? '1rem' : '1.5rem'}
            className={Styles.Location_modal}
            bannerInfo={{
                title: (
                    <Text type="sub2" style={{ fontWeight: 400 }}>
                        <span style={{ fontWeight: 600 }}>Warning:</span> This
                        will disconnect the DoorDash location from any existing
                        integration and overwrite its menu and menu pricing.
                        Menus and menu pricing will sync one-way from SpotOn to
                        DoorDash.
                    </Text>
                ),
                variant: 'warning',
            }}
        >
            {(providerAddress || providerStoreName) && (
                <span
                    data-testid="provider-info"
                    className={Styles.Location_providerInfo}
                >
                    <Text
                        as="label"
                        style={{ fontWeight: '500', letterSpacing: '0.2px' }}
                        color="#0F1221"
                    >
                        Your SpotOn Location:
                    </Text>
                    {providerStoreName && (
                        <Text
                            type="bold"
                            fontSize={18}
                            data-testid="provider-store-name"
                        >
                            {providerStoreName}
                        </Text>
                    )}
                    {providerAddress && (
                        <Text
                            color="#0F1221"
                            data-testid="provider-store-address"
                        >{`${providerAddress.street_address}, ${providerAddress.city}, ${providerAddress.state} ${providerAddress.zip}, ${providerAddress.country}`}</Text>
                    )}
                </span>
            )}
            <span
                className={Styles.Location_doorDashLocation}
                data-testid="doordash-location-info"
            >
                <Text
                    as="label"
                    style={{ fontWeight: '500', letterSpacing: '0.2px' }}
                    color="#0F1221"
                >
                    Your DoorDash Location:
                </Text>
                <PaginateDropdown
                    onChange={(value) => setSelectedLocation(value as Option)}
                    value={locationErrorMessage ? null : selectedLocation}
                    placeholder={
                        locationErrorMessage
                            ? 'No locations found'
                            : 'Select a location'
                    }
                    styles={{
                        menuPortal: (base) => ({
                            ...base,
                            zIndex: 100,
                        }),
                        control: (base) => ({
                            ...base,
                            borderRadius: '0.5rem',
                            borderColor: locationErrorMessage
                                ? '#D54041 !important'
                                : '#DBDFE5 !important',
                            border: locationErrorMessage
                                ? '2px solid #D54041 !important'
                                : '1px solid #DBDFE5 !important',
                        }),
                    }}
                    className={Styles.Location_dropdown}
                    loadOptions={loadOptions}
                    cacheUniqs={[locationErrorMessage]}
                />
                {locationErrorMessage && (
                    <LocationError
                        locationErrorMessage={locationErrorMessage}
                    />
                )}
            </span>
        </ReusableModal>
    );
}
