import React from 'react';
import { Button } from 'spoton-lib';

import Styles from './RoundedButton.module.scss';

interface IRoundedButton {
    onClick: () => void;
    buttonText?: string;
    variant?: 'primary' | 'secondary' | 'tertiary' | 'ghost';
    width?: number;
    testId?: string;
    isLoading?: boolean;
}

export function RoundedButton({
    onClick,
    buttonText,
    variant = 'primary',
    width,
    testId,
    isLoading = false,
}: IRoundedButton) {
    return (
        <Button
            data-testid={testId}
            variant={variant}
            onClick={onClick}
            isLoading={isLoading}
            style={{ width }}
            className={`${Styles[`RoundedButton_${variant}Button`]} ${Styles.RoundedButton}`}
        >
            {buttonText}
        </Button>
    );
}
