import { Resource } from 'i18next';

import translationEN from './en.json';
import translationES from './es.json';

export const namespace = 'doordashFrontend';

export const resources: Resource = {
    en: {
        [namespace]: translationEN,
    },
    es: {
        [namespace]: translationES,
    },
};
