import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit';

import styles from './MerchantOnboarding.module.scss';
import { useAppDispatch, useAppSelector } from 'api/store';
import * as merchantOnboardingSlice from './MerchantOnboarding.slice';
import * as menuSlice from '../menus/Menus.slice';
import {
    MenuStatus,
    StoreCandidates,
    OnboardingStatus as OnboardingStatusEnum,
    BusinessHoursExistStatus,
} from './MerchantOnboarding.types';
import { getConfigVar } from 'features/common';
import {
    ActivationModal,
    BusinessHoursModal,
    ConnectDoordashModal,
    LandingPage,
    Location,
    OnboardingStatus,
    SessionExpiredModal,
    SuccessModal,
} from './components';
import { PriceMarkupModal } from 'features/price-markup/PriceMarkupModal.component';
import { MenusModal } from 'features/menus/MenusModal.component';
import { MenuResponse } from 'features/menus/Menus.types';

export function MerchantOnboarding() {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const [isBusinessHoursModalOpen, setIsBusinessHoursModalOpen] =
        useState<boolean>(false);
    const [isConnectDoordashModalOpen, setIsConnectDoordashModalOpen] =
        useState<boolean>(false);
    const [isSyncLocationModalOpen, setIsSyncLocationModalOpen] =
        useState<boolean>(false);
    const [isOnboardingStatusModalOpen, setIsOnboardingStatusModalOpen] =
        useState<boolean>(false);
    const [isActivationModalOpen, setIsActivationModalOpen] =
        useState<boolean>(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] =
        useState<boolean>(false);
    const [isSessionExpired, setIsSessionExpired] = useState<boolean>(false);
    const [isPriceMarkupModalOpen, setIsPriceMarkupModalOpen] =
        useState<boolean>(false);
    const [isUserTriesToSetBusinessHours, setIsUserTriesToSetBusinessHours] =
        useState<boolean>(false);
    const [countOfStoreIntegrateAPICall, setCountOfStoreIntegrateAPICall] =
        useState<number>(0);

    const [isMenusModalOpen, setIsMenusModalOpen] = useState<boolean>(false);
    const [menus, setMenus] = useState<MenuResponse[]>([]);

    const {
        businessLocationId,
        onboardingId,
        onboardingStatus,
        exchangeCode,
        providerStoreName,
        isActivationRequested,
        isBusinessHoursExist,
        isExpressMerchant,
        isMenusConfirmed,
    } = useAppSelector((state) => state.merchantOnboarding);

    const { loading } = useAppSelector((state) => state.markupPrice);

    const onSyncLocationConfirm = async (selectedLocation: StoreCandidates) => {
        if (businessLocationId) {
            const res = await dispatch(
                merchantOnboardingSlice.initializeOnboarding({
                    doordash_store_uuid: selectedLocation.doordash_store_uuid,
                    location_id: businessLocationId,
                    exchange_code: localStorage.getItem('exchangeCode'),
                    provider_store_name: providerStoreName
                        ? providerStoreName
                        : '',
                    store_name: selectedLocation.name,
                    provider_store_address: selectedLocation.address,
                    business_name: selectedLocation.business_name,
                    business_uuid: selectedLocation.business_uuid,
                    dd_store_address: selectedLocation.address,
                }),
            );

            if (res && isFulfilled(res)) {
                if (res.payload.status === 'success') {
                    const getStoreIntegrationStatusRes = await dispatch(
                        merchantOnboardingSlice.getStoreIntegrationStatus({
                            businessLocationId,
                        }),
                    );
                    if (
                        getStoreIntegrationStatusRes &&
                        isFulfilled(getStoreIntegrationStatusRes)
                    ) {
                        if (
                            getStoreIntegrationStatusRes.payload.data
                                .onboarding_status !==
                            OnboardingStatusEnum.ABANDONED
                        ) {
                            setIsSyncLocationModalOpen(false);
                        }
                        if (
                            getStoreIntegrationStatusRes.payload.data
                                .onboarding_status ===
                            OnboardingStatusEnum.MENU_REQUESTED
                        ) {
                            setIsOnboardingStatusModalOpen(true);
                        }
                    }
                }
            }

            if (res && isRejectedWithValue(res)) {
                if (res.payload?.errors.statusCode === 440) {
                    setIsSessionExpired(true);
                }
            }
        }
    };

    const onClickTryAgainBtn = async () => {
        if (businessLocationId) {
            const menuRequestRes = await dispatch(
                merchantOnboardingSlice.menuRequest({ businessLocationId }),
            );
            if (menuRequestRes && isFulfilled(menuRequestRes)) {
                if (menuRequestRes.payload.status === 'success') {
                    dispatch(merchantOnboardingSlice.setIsError(false));
                    dispatch(
                        merchantOnboardingSlice.setOnboardingStatus(
                            OnboardingStatusEnum.MENU_REQUESTED,
                        ),
                    );
                    if (!isOnboardingStatusModalOpen) {
                        setIsOnboardingStatusModalOpen(true);
                    }
                }
            }
        }
    };

    const onClickActivationBtn = async () => {
        if (onboardingId) {
            const activateIntegrationRes = await dispatch(
                merchantOnboardingSlice.activateIntegration({
                    onboarding_id: onboardingId,
                }),
            );
            if (activateIntegrationRes && isFulfilled(activateIntegrationRes)) {
                if (activateIntegrationRes.payload.status === 'success') {
                    if (businessLocationId) {
                        const getStoreIntegrationStatusRes = await dispatch(
                            merchantOnboardingSlice.getStoreIntegrationStatus({
                                businessLocationId,
                            }),
                        );
                        // if (isActivationModalOpen) {
                        //     setIsActivationModalOpen(false);
                        // }
                        if (
                            getStoreIntegrationStatusRes &&
                            isFulfilled(getStoreIntegrationStatusRes)
                        ) {
                            if (
                                getStoreIntegrationStatusRes.payload.data
                                    .onboarding_status ===
                                'INTEGRATION_ACTIVATED'
                            ) {
                                setIsActivationModalOpen(false);
                                setIsSuccessModalOpen(true);
                            }
                        }
                    }
                }
            }
        }
    };

    const getMenuStatus = async () => {
        if (onboardingId && businessLocationId) {
            const menuStatusRes = await dispatch(
                merchantOnboardingSlice.getMenuStatus({ onboardingId }),
            );
            if (menuStatusRes && isFulfilled(menuStatusRes)) {
                if (menuStatusRes.payload.status === 'success') {
                    if (
                        menuStatusRes.payload.data.status ===
                            MenuStatus.SUCCESS ||
                        menuStatusRes.payload.data.status === MenuStatus.FAILED
                    ) {
                        if (!isOnboardingStatusModalOpen) {
                            setIsOnboardingStatusModalOpen(true);
                        }
                    }
                }
            }
        }
    };

    const getStoreIntegrationStatus = async () => {
        if (businessLocationId) {
            const getStoreIntegrationStatusRes = await dispatch(
                merchantOnboardingSlice.getStoreIntegrationStatus({
                    businessLocationId,
                }),
            );
            if (
                getStoreIntegrationStatusRes &&
                isFulfilled(getStoreIntegrationStatusRes)
            ) {
                const status =
                    getStoreIntegrationStatusRes.payload.data.onboarding_status;
                if (
                    (status === OnboardingStatusEnum.MENU_BLOCK ||
                        status === OnboardingStatusEnum.MENU_QUALIFIED) &&
                    isActivationRequested === false &&
                    onboardingId
                ) {
                    getMenuStatus();
                } else if (status === OnboardingStatusEnum.ABANDONED) {
                    setIsOnboardingStatusModalOpen(false);
                    setIsSyncLocationModalOpen(true);
                } else if (
                    status === OnboardingStatusEnum.INTEGRATION_ACTIVATED
                ) {
                    setIsActivationModalOpen(false);
                    setIsSuccessModalOpen(true);
                } else if (
                    getStoreIntegrationStatusRes.payload.data
                        .business_hours_validate ===
                        BusinessHoursExistStatus.UPDATED &&
                    status === OnboardingStatusEnum.PENDING
                ) {
                    setIsUserTriesToSetBusinessHours(false);
                    if (!isBusinessHoursModalOpen) {
                        setIsBusinessHoursModalOpen(true);
                    }
                }
            }

            if (isUserTriesToSetBusinessHours) {
                setCountOfStoreIntegrateAPICall((prev) => prev + 1);
            }
        }
    };

    const getMenus = async () => {
        if (businessLocationId) {
            setMenus([]);
            dispatch(menuSlice.setLoading(true));
            const response = await dispatch(
                menuSlice.getMenuList({ businessLocationId }),
            );
            dispatch(menuSlice.setLoading(false));

            if (response && isFulfilled(response)) {
                if (response.payload.status === 'success') {
                    setMenus([...response.payload.data]);
                }
            }
        }
    };

    useEffect(() => {
        if (
            onboardingStatus === OnboardingStatusEnum.MENU_REQUESTED ||
            isActivationRequested ||
            (isUserTriesToSetBusinessHours &&
                countOfStoreIntegrateAPICall <= 10)
        ) {
            const intervalTime = isUserTriesToSetBusinessHours
                ? 5000
                : parseInt(getConfigVar('REACT_APP_POLLING_INTERVAL_TIME'));

            const intervalId = setInterval(() => {
                getStoreIntegrationStatus();
            }, intervalTime);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [
        onboardingStatus,
        isActivationRequested,
        isUserTriesToSetBusinessHours,
        countOfStoreIntegrateAPICall,
    ]);

    useEffect(() => {
        const exchangeCodeFromUrl = searchParams.get('code');
        if (exchangeCodeFromUrl) {
            localStorage.setItem('exchangeCode', exchangeCodeFromUrl);
        }
    }, [searchParams]);

    useEffect(() => {
        if (
            exchangeCode &&
            !onboardingId &&
            isBusinessHoursExist === BusinessHoursExistStatus.UPDATED &&
            menus.length > 0
        ) {
            if (!isExpressMerchant && menus.length > 1 && !isMenusConfirmed) {
                setIsMenusModalOpen(true);
            } else {
                setIsPriceMarkupModalOpen(true);
            }
        }
    }, [
        exchangeCode,
        onboardingId,
        isBusinessHoursExist,
        isExpressMerchant,
        isMenusConfirmed,
        menus,
    ]);

    useEffect(() => {
        if (!isSyncLocationModalOpen) {
            dispatch(
                merchantOnboardingSlice.setLocationErrorMessage(undefined),
            );
        }
    }, [isSyncLocationModalOpen, exchangeCode]);

    useEffect(() => {
        getMenus();
    }, [businessLocationId]);

    return (
        <div className={styles.MerchantOnboarding}>
            <LandingPage
                setIsBusinessHoursModalOpen={setIsBusinessHoursModalOpen}
                setIsSyncLocationModalOpen={setIsSyncLocationModalOpen}
                setIsUserTriesToSetBusinessHours={
                    setIsUserTriesToSetBusinessHours
                }
                // getMenus={getMenus}
                setMenuSelectionModalOpen={setIsMenusModalOpen}
                onTryMenuSyncAgain={onClickTryAgainBtn}
                onClickActivationBtn={onClickActivationBtn}
            />
            <PriceMarkupModal
                isOpen={isPriceMarkupModalOpen}
                setIsOpen={setIsPriceMarkupModalOpen}
                setLocationModal={setIsSyncLocationModalOpen}
                isButtonDisable={loading}
            />

            <MenusModal
                isOpen={isMenusModalOpen}
                setIsOpen={setIsMenusModalOpen}
                setMarkupPriceModal={setIsPriceMarkupModalOpen}
                isButtonDisable={loading}
            />

            <BusinessHoursModal
                isBusinessHoursModalOpen={isBusinessHoursModalOpen}
                setIsBusinessHoursModalOpen={setIsBusinessHoursModalOpen}
                setIsConnectDoordashModalOpen={setIsConnectDoordashModalOpen}
                setIsUserTriesToSetBusinessHours={
                    setIsUserTriesToSetBusinessHours
                }
            />
            <ConnectDoordashModal
                isConnectDoordashModalOpen={isConnectDoordashModalOpen}
                setIsConnectDoordashModalOpen={setIsConnectDoordashModalOpen}
                setIsBusinessHoursModalOpen={setIsBusinessHoursModalOpen}
            />
            <Location
                isSyncLocationModalOpen={isSyncLocationModalOpen}
                setIsSyncLocationModalOpen={setIsSyncLocationModalOpen}
                onSyncLocationConfirm={onSyncLocationConfirm}
                setIsSessionExpired={setIsSessionExpired}
            />
            <OnboardingStatus
                isOnboardingStatusModalOpen={isOnboardingStatusModalOpen}
                setIsOnboardingStatusModalOpen={setIsOnboardingStatusModalOpen}
                setIsActivationModalOpen={setIsActivationModalOpen}
                onClickTryAgainBtn={onClickTryAgainBtn}
            />
            <ActivationModal
                isActivationModalOpen={isActivationModalOpen}
                setIsActivationModalOpen={setIsActivationModalOpen}
                onClickActivationBtn={onClickActivationBtn}
            />
            <SuccessModal
                isSuccessModalOpen={isSuccessModalOpen}
                setIsSuccessModalOpen={setIsSuccessModalOpen}
            />
            <SessionExpiredModal
                isSessionExpired={isSessionExpired}
                setIsSessionExpired={setIsSessionExpired}
            />
        </div>
    );
}
