import React from 'react';

export function ProgressIndicatorIcon() {
    return (
        <svg
            width={24}
            height={25}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 24.4995C18.6274 24.4995 24 19.1269 24 12.4995C24 5.87209 18.6274 0.499512 12 0.499512C5.37258 0.499512 0 5.87209 0 12.4995C0 19.1269 5.37258 24.4995 12 24.4995Z"
                fill="#00AB4F"
            />
            <path
                d="M6.54541 12.8632L10.1818 16.4995L17.4545 9.22681"
                stroke="white"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
