// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExternalLinkButton_ExternalLinkButton_button__98fqn{gap:.5rem;padding:.625rem 1.5rem;color:#0f1221;background-color:#eff5ff !important;border:0 !important;border-radius:6rem}`, "",{"version":3,"sources":["webpack://./src/features/common/components/external_link_button/ExternalLinkButton.module.scss"],"names":[],"mappings":"AACI,qDACI,SAAA,CACA,sBAAA,CACA,aAAA,CACA,mCAAA,CACA,mBAAA,CACA,kBAAA","sourcesContent":[".ExternalLinkButton {\n    &_button{\n        gap: 0.5rem;\n        padding: 0.625rem 1.5rem;\n        color: #0F1221;\n        background-color: #EFF5FF !important;\n        border: 0 !important;\n        border-radius: 6rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExternalLinkButton_button": `ExternalLinkButton_ExternalLinkButton_button__98fqn`
};
export default ___CSS_LOADER_EXPORT___;
