import React from 'react';

export function ArrowLeftIcon() {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="arrow-left">
                <path
                    id="Vector"
                    d="M19 12H5M5 12L11 6M5 12L11 18"
                    stroke="#2D71E8"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}
