import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showToast } from 'spoton-lib';

import {
    ActivateIntegrationRequest,
    BusinessHoursConfirmationRequest,
    BusinessHoursExistStatus,
    GetStoreListRequest,
    GetStoreListResponse,
    IMerchantOnboardingSliceState,
    InitializeOnboardingRequest,
    InitializeOnboardingResponse,
    MenuStatusRequest,
    MenusRequest,
    OnboardingStatus,
    RemoveSmartButtonModalRequest,
    RestartProcessRequest,
    SaveSelectedLocationRequest,
    SignupUrlRequest,
    StepsStatus,
    StoreIntegrationStatusRequest,
    StorePauseRequest,
    getBusinessHoursStatusRequest,
} from './MerchantOnboarding.types';
import * as API from './MerchantOnboarding.api';
import {
    abandonedStatusErrorMessage,
    steps,
} from './MerchantOnboarding.utility';
import { ErrorResult, SuccessResult } from 'utils/axios.types';

export const initialState: IMerchantOnboardingSliceState = {
    loading: false,
    isInitialPageLoading: true,
    isBusinessHoursExist: BusinessHoursExistStatus.UNKNOWN,
    onboardingId: localStorage.getItem('onboardingId'),
    providerStoreName: null,
    stepsList: steps,
    storeList: [],
    locationErrorMessage: undefined,
    exchangeCode: null,
    isError: false,
    isOrderActive: false,
    isActivationRequested: false,
    exclusionCodeDetails: '',
    isSmartButtonModelClosed: false,
    isMenusConfirmed: false,
};

export const merchantOnboardingSlice = createSlice({
    name: 'merchantOnboarding',
    initialState,
    reducers: {
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setIsInitialPageLoading: (
            state,
            { payload }: PayloadAction<boolean>,
        ) => {
            state.isInitialPageLoading = payload;
        },
        setLocationErrorMessage: (
            state,
            {
                payload,
            }: PayloadAction<
                IMerchantOnboardingSliceState['locationErrorMessage']
            >,
        ) => {
            state.locationErrorMessage = payload;
        },
        setExchangeCode: (state, { payload }: PayloadAction<string | null>) => {
            state.exchangeCode = payload;
        },
        setIsError: (state, { payload }: PayloadAction<boolean>) => {
            state.isError = payload;
            state.menuStatus = undefined;
        },
        setbusinessLocationId: (
            state,
            { payload }: PayloadAction<string | undefined>,
        ) => {
            state.businessLocationId = payload;
        },
        setIsExpressMerchant: (
            state,
            { payload }: PayloadAction<boolean | undefined>,
        ) => {
            state.isExpressMerchant = payload;
        },
        setOnboardingStatus: (
            state,
            { payload }: PayloadAction<OnboardingStatus | undefined>,
        ) => {
            state.onboardingStatus = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            getStoreIntegrationStatus.fulfilled,
            (state, { payload }) => {
                state.onboardingId = payload.data.onboarding_id;
                payload.data.is_business_hours_confirmed
                    ? (state.exchangeCode =
                          localStorage.getItem('exchangeCode'))
                    : localStorage.removeItem('exchangeCode');
                state.isError = payload.data.is_error;
                state.stepsList = state.stepsList.map((step) => {
                    return {
                        ...step,
                        isProcessCompleted:
                            payload.data[step.key as keyof StepsStatus],
                    };
                });
                state.stepsList[1]['isProcessCompleted'] =
                    (payload.data.is_business_hours_confirmed &&
                        localStorage.getItem('exchangeCode')) ||
                    payload.data.locationStatus
                        ? true
                        : false;
                state.selectedDdStoreDetails = {
                    name: payload.data.store_name,
                    doordash_store_uuid: payload.data.dd_store_uuid,
                    address: payload.data.dd_store_address,
                    lat: payload.data.lat,
                    lng: payload.data.lng,
                    business_name: payload.data.business_name,
                    business_uuid: payload.data.business_uuid,
                    is_active_on_integration:
                        payload.data.is_active_on_integration,
                    is_eligible_to_onboard: payload.data.is_eligible_to_onboard,
                };
                state.ddStoreId = payload.data.dd_store_id;
                state.providerStoreName = payload.data.provider_store_name;
                state.providerAddress = payload.data.provider_address;
                state.isOrderActive = payload.data.is_order_active;
                state.onboardingStatus = payload.data.onboarding_status;
                state.exclusionCodeDetails = payload.data.details;
                state.exclusionCode = payload.data.exclusion_code;
                state.isActivationRequested =
                    payload.data.is_activation_requested;
                state.isSmartButtonModelClosed = payload.data.is_model_closed;
                state.isMenusConfirmed = payload.data.is_menus_confirmed;
                state.isBusinessHoursExist =
                    payload.data.business_hours_validate;
                if (
                    payload.data.onboarding_status ===
                        OnboardingStatus.ABANDONED &&
                    payload.data.exclusion_code
                ) {
                    state.locationErrorMessage = {
                        message: abandonedStatusErrorMessage(
                            payload.data.exclusion_code,
                            payload.data.details,
                        ),
                    };
                } else {
                    state.locationErrorMessage = undefined;
                }
            },
        );
        builder.addCase(getMenuStatus.fulfilled, (state, { payload }) => {
            state.menuStatus = payload.data;
        });
        builder.addCase(restartProcess.fulfilled, (state, { payload }) => {
            if (payload.data.success) {
                state.isError = false;
                state.menuStatus = undefined;
                state.onboardingStatus = undefined;
                if (
                    state.isBusinessHoursExist ===
                    BusinessHoursExistStatus.UNKNOWN
                ) {
                    localStorage.removeItem('exchangeCode');
                }
            }
        });
        builder.addCase(
            getBusinessHoursStatus.fulfilled,
            (state, { payload }) => {
                state.isBusinessHoursExist =
                    payload.data.business_hours_validate;
                if (
                    payload.data.business_hours_validate ===
                    BusinessHoursExistStatus.NOT_UPDATED
                ) {
                    state.isError = true;
                }
            },
        );
        builder.addCase(getSignupUrl.fulfilled, (state, { payload }) => {
            state.isBusinessHoursExist = payload.data.business_hours_validate;
            if (
                payload.data.business_hours_validate ===
                BusinessHoursExistStatus.NOT_UPDATED
            ) {
                state.isError = true;
            }
        });
        builder.addCase(getStoreList.rejected, (state, { payload }) => {
            if (payload?.errors.statusCode === 440) {
                state.stepsList[1]['isProcessCompleted'] = false;
                localStorage.removeItem('exchangeCode');
            }
        });
        builder.addCase(initializeOnboarding.rejected, (state, { payload }) => {
            if (payload?.errors.statusCode === 440) {
                state.stepsList[1]['isProcessCompleted'] = false;
                localStorage.removeItem('exchangeCode');
            }
        });
        builder.addCase(
            businessHoursConfirmation.fulfilled,
            (state, { payload }) => {
                if (payload.status === 'success') {
                    const businessHoursStepIndex = state.stepsList.findIndex(
                        (step) => step.key === 'is_business_hours_confirmed',
                    );
                    state.stepsList[businessHoursStepIndex].isProcessCompleted =
                        true;
                }
            },
        );
    },
});

export const {
    setLoading,
    setIsInitialPageLoading,
    setLocationErrorMessage,
    setExchangeCode,
    setIsError,
    setOnboardingStatus,
    setbusinessLocationId,
    setIsExpressMerchant,
} = merchantOnboardingSlice.actions;

export const getSignupUrl = createAsyncThunk(
    'merchantOnboarding/getSignupUrl',
    async (request: SignupUrlRequest, { rejectWithValue }) => {
        const response = await API.getSignupUrl(request);
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const getStoreList = createAsyncThunk<
    SuccessResult<GetStoreListResponse>,
    GetStoreListRequest,
    { rejectValue: ErrorResult }
>(
    'merchantOnboarding/getStoreList',
    async (request: GetStoreListRequest, { rejectWithValue, dispatch }) => {
        dispatch(setLoading(true));
        const response = await API.getStoreList(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            dispatch(
                setLocationErrorMessage({
                    statusCode: response.errors.statusCode,
                    message: response.errors.error,
                }),
            );
            return rejectWithValue(response as ErrorResult);
        }
    },
);

export const activateIntegration = createAsyncThunk(
    'merchantOnboarding/activateIntegration',
    async (
        request: ActivateIntegrationRequest,
        { rejectWithValue, dispatch },
    ) => {
        dispatch(setLoading(true));
        const response = await API.activateIntegration(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const initializeOnboarding = createAsyncThunk<
    SuccessResult<InitializeOnboardingResponse>,
    InitializeOnboardingRequest,
    { rejectValue: ErrorResult }
>(
    'merchantOnboarding/initializeOnboarding',
    async (
        request: InitializeOnboardingRequest,
        { rejectWithValue, dispatch },
    ) => {
        dispatch(setLoading(true));
        const response = await API.initializeOnboarding(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            showToast({
                content: response.errors.error,
                containerId: 'toast-container',
                variant: 'danger',
                position: 'top-center',
            });
            return rejectWithValue(response as ErrorResult);
        }
    },
);

export const getMenuStatus = createAsyncThunk(
    'merchantOnboarding/getMenuStatus',
    async (request: MenuStatusRequest, { rejectWithValue, dispatch }) => {
        dispatch(setLoading(true));
        const response = await API.getMenuStatus(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const getStoreIntegrationStatus = createAsyncThunk(
    'merchantOnboarding/getStoreIntegrationStatus',
    async (
        request: StoreIntegrationStatusRequest,
        { rejectWithValue, dispatch },
    ) => {
        const response = await API.getStoreIntegrationStatus(request);
        dispatch(setIsInitialPageLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const menuRequest = createAsyncThunk(
    'merchantOnboarding/menuRequest',
    async (request: MenusRequest, { rejectWithValue, dispatch }) => {
        dispatch(setLoading(true));
        const response = await API.menuRequest(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const storePause = createAsyncThunk(
    'merchantOnboarding/storePause',
    async (request: StorePauseRequest, { rejectWithValue, dispatch }) => {
        dispatch(setLoading(true));
        const response = await API.storePause(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            showToast({
                content: response.data.message,
                containerId: 'toast-container',
                position: 'top-center',
            });
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const restartProcess = createAsyncThunk(
    'merchantOnboarding/restartProcess',
    async (request: RestartProcessRequest, { rejectWithValue, dispatch }) => {
        dispatch(setIsInitialPageLoading(true));
        dispatch(setLoading(true));
        const response = await API.restartProcess(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const saveSelectedLocation = createAsyncThunk(
    'merchantOnboarding/saveSelectedLocation',
    async (
        request: SaveSelectedLocationRequest,
        { rejectWithValue, dispatch },
    ) => {
        dispatch(setLoading(true));
        const response = await API.saveSelectedLocation(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const removeSmartButtonModal = createAsyncThunk(
    'merchantOnboarding/removeSmartButtonModal',
    async (request: RemoveSmartButtonModalRequest, { rejectWithValue }) => {
        const response = await API.removeSmartButtonModal(request);
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const getBusinessHoursStatus = createAsyncThunk(
    'merchantOnboarding/getBusinessHoursStatus',
    async (
        request: getBusinessHoursStatusRequest,
        { rejectWithValue, dispatch },
    ) => {
        dispatch(setLoading(true));
        const response = await API.getBusinessHoursStatus(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const businessHoursConfirmation = createAsyncThunk(
    'merchantOnboarding/businessHoursConfirmation',
    async (
        request: BusinessHoursConfirmationRequest,
        { rejectWithValue, dispatch },
    ) => {
        dispatch(setLoading(true));
        const response = await API.businessHoursConfirmation(request);
        dispatch(setLoading(false));
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);
