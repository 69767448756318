import React from 'react';
import { Button } from 'spoton-lib';
import { useNavigate } from 'react-router-dom';

import styles from './PrevousPageLink.module.scss';
import { ArrowLeftIcon } from 'features/price-markup/assets';

type PreviousPageLinkType = {
    hyperlink: string;
    title: string;
};

export const PreviousPageLink = (props: PreviousPageLinkType) => {
    const { title, hyperlink } = props;
    const navigate = useNavigate();

    return (
        <Button
            variant="ghost"
            onClick={() => navigate(hyperlink)}
            className={styles.PreviousPageLink}
        >
            <ArrowLeftIcon /> {title}
        </Button>
    );
};
