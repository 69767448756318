import React from 'react';
import {
    Banner,
    Button,
    LoadingIndicator,
    Modal,
    ModalFooter,
    ModalHeader,
    Text,
} from 'spoton-lib';

import styles from './ReusableModal.module.scss';

interface IReusableModal {
    isOpen: boolean;
    title: string | JSX.Element;
    showFooter?: boolean;
    showCancelBtn?: boolean;
    showLoadingIndicator?: boolean;
    children: React.ReactNode;
    onCancel: () => void;
    onClickSecondaryConfirmButton?: () => void;
    onConfirm?: () => void;
    cancelText?: string;
    secondaryConfirmButtonText?: string;
    confirmText?: string;
    confirmButtonColor?: string;
    isConfirmBtnLoading?: boolean;
    isConfirmBtnDisabled?: boolean;
    isFooterBtnsDisabled?: boolean;
    gap?: number | string;
    headerClassName?: string;
    contentClassName?: string;
    className?: string;
    bannerInfo?: {
        title?: string | React.ReactNode;
        message?: React.ReactNode;
        variant: 'danger' | 'informative' | 'success' | 'warning';
    };
}

export function ReusableModal(props: IReusableModal) {
    const {
        children,
        isOpen,
        title,
        showFooter = true,
        showCancelBtn = true,
        showLoadingIndicator = false,
        cancelText = 'Close',
        secondaryConfirmButtonText,
        confirmText = 'Continue',
        confirmButtonColor = '#2D71E8',
        onCancel,
        onClickSecondaryConfirmButton,
        onConfirm,
        isConfirmBtnLoading = false,
        isConfirmBtnDisabled = false,
        isFooterBtnsDisabled = false,
        gap,
        headerClassName,
        contentClassName = '',
        className = '',
        bannerInfo,
    } = props;

    const Title = () => {
        if (typeof title === 'string') {
            return <Text type="h4">{title}</Text>;
        }
        return title;
    };

    const headerComponent = (
        <ModalHeader
            className={`${styles.ReusableModal_header} ${headerClassName}`}
            content={<Title />}
            onRequestClose={onCancel}
        />
    );

    const footerComponent = (
        <ModalFooter className={styles.ReusableModal_footer}>
            {showCancelBtn && (
                <Button
                    variant="secondary"
                    onClick={onCancel}
                    disabled={isFooterBtnsDisabled}
                    className={styles.ReusableModal_cancelButton}
                    data-testid="modal-cancel-button"
                >
                    {cancelText}
                </Button>
            )}
            {onClickSecondaryConfirmButton && (
                <Button
                    variant="secondary"
                    onClick={onClickSecondaryConfirmButton}
                    disabled={isFooterBtnsDisabled}
                    className={styles.ReusableModal_saveButton}
                    data-testid="modal-save-button"
                >
                    {secondaryConfirmButtonText}
                </Button>
            )}
            {onConfirm && (
                <Button
                    onClick={onConfirm}
                    className={styles.ReusableModal_confirmButton}
                    style={{
                        backgroundColor: confirmButtonColor,
                        border: `1px solid ${confirmButtonColor}`,
                    }}
                    disabled={isFooterBtnsDisabled || isConfirmBtnDisabled}
                    data-testid="modal-confirm-button"
                    isLoading={isConfirmBtnLoading}
                >
                    {confirmText}
                </Button>
            )}
        </ModalFooter>
    );

    return (
        <>
            <Modal
                shouldCloseOnOverlayClick={false}
                testId="modal"
                ariaHideApp={false}
                className={`${styles.ReusableModal} ${className}`}
                isOpen={isOpen}
                onRequestClose={onCancel}
                headerComponent={headerComponent}
                footerComponent={showFooter ? footerComponent : undefined}
            >
                {showLoadingIndicator && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <LoadingIndicator
                            data-testid="loading-indicator"
                            size="sm"
                            className={styles.ReusableModal_loader}
                            greyAnimation
                        />
                    </div>
                )}
                {bannerInfo && (
                    <Banner
                        title={bannerInfo.title}
                        message={bannerInfo.message}
                        variant={bannerInfo.variant}
                        className={styles.ReusableModal_banner}
                    />
                )}
                <div
                    className={`${styles.ReusableModal_content} ${contentClassName} ${showLoadingIndicator ? styles.ReusableModal_content___loading : ''}`}
                    style={{ gap }}
                >
                    {children}
                </div>
            </Modal>
        </>
    );
}
