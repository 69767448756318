import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'spoton-lib';

import 'i18n/config';
import { routes } from 'app/routes';
import { ErrorBoundaryGeneric } from 'features';
import { setupStore } from 'api/store';
import {
    setbusinessLocationId,
    setIsExpressMerchant,
} from 'features/merchant_onboarding/MerchantOnboarding.slice';

export function App({
    businessLocationId,
    isExpressMerchant,
}: {
    businessLocationId: string | undefined;
    isExpressMerchant?: boolean;
}): JSX.Element {
    const store = setupStore();
    useEffect(() => {
        store.dispatch(setbusinessLocationId(businessLocationId));
        store.dispatch(setIsExpressMerchant(isExpressMerchant));
    }, []);
    return (
        <React.StrictMode>
            <ErrorBoundaryGeneric>
                <ToastContainer containerId="toast-container" limit={1} />
                <Provider store={store}>
                    <RouterProvider router={routes} />
                </Provider>
            </ErrorBoundaryGeneric>
        </React.StrictMode>
    );
}
