import React from 'react';
import { Icon, Tooltip } from 'spoton-lib';

import styles from './InfoTooltip.module.scss';

interface IInfoTooltip {
    tooltipContent: string;
    variant?:
        | 'bottom'
        | 'bottomLeft'
        | 'topLeft'
        | 'topRight'
        | 'bottomRight'
        | 'top'
        | 'left'
        | 'right'
        | 'rightTop'
        | 'rightBottom'
        | 'leftTop'
        | 'leftBottom';
}

export function InfoTooltip(props: IInfoTooltip) {
    const { tooltipContent, variant = 'top' } = props;
    return (
        <Tooltip
            tooltipClassName={styles.InfoTooltip_tooltip}
            className={styles.InfoTooltip_tooltip_wrapper}
            tooltipContent={tooltipContent}
            variant={variant}
        >
            <Icon name="InfoCircleIcon" size={18} />
        </Tooltip>
    );
}
