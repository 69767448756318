import React from 'react';
import { Hyperlink, Text } from 'spoton-lib';
import { isFulfilled } from '@reduxjs/toolkit';

import { getConfigVar, ReusableModal } from 'features/common';
import { useAppDispatch, useAppSelector } from 'api/store';
import { getSignupUrl } from 'features/merchant_onboarding/MerchantOnboarding.slice';
import styles from './ConnectDoordashModal.module.scss';
import { BusinessHoursExistStatus } from 'features/merchant_onboarding/MerchantOnboarding.types';

interface IConnectDoordashModal {
    isConnectDoordashModalOpen: boolean;
    setIsConnectDoordashModalOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setIsBusinessHoursModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ConnectDoordashModal(props: IConnectDoordashModal) {
    const {
        isConnectDoordashModalOpen,
        setIsConnectDoordashModalOpen,
        setIsBusinessHoursModalOpen,
    } = props;

    const dispatch = useAppDispatch();
    const { businessLocationId, stepsList } = useAppSelector(
        (state) => state.merchantOnboarding,
    );
    const onConfirm = () => {
        window.location.href = getConfigVar('REACT_APP_DOORDASH_SIGNIN_URL');
    };

    const goToSignUpLink = async () => {
        if (businessLocationId) {
            const res = await dispatch(getSignupUrl({ businessLocationId }));

            if (res && isFulfilled(res)) {
                if (res.payload.status === 'success') {
                    const businessHoursStepIndex = stepsList.findIndex(
                        (step) => step.key === 'is_business_hours_confirmed',
                    );
                    if (
                        res.payload.data.business_hours_validate ===
                            BusinessHoursExistStatus.UPDATED &&
                        stepsList[businessHoursStepIndex].isProcessCompleted
                    ) {
                        window.location.href = res.payload.data.signup_url;
                    } else {
                        setIsBusinessHoursModalOpen(true);
                    }
                }
            }
        }
    };

    return (
        <ReusableModal
            title="Connect DoorDash to SpotOn"
            isOpen={isConnectDoordashModalOpen}
            onCancel={() => setIsConnectDoordashModalOpen(false)}
            showCancelBtn={false}
            confirmText="Log into DoorDash"
            onConfirm={onConfirm}
            secondaryConfirmButtonText="Sign up"
            onClickSecondaryConfirmButton={goToSignUpLink}
        >
            <Text>
                Log in to your DoorDash Merchant account, or
                <Hyperlink
                    onClick={goToSignUpLink}
                    className={styles.ConnectDoordashmodal_signupLink}
                >
                    sign up for an account.
                </Hyperlink>
            </Text>
        </ReusableModal>
    );
}
