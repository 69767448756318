import { AxiosAPI } from 'utils/axios';
import { REQUEST_METHOD } from 'utils/constants';
import { ROUTES } from 'utils/endpoints';
import {
    GetMenuListRequest,
    GetMenuListResponse,
    UpdateMenuListPayload,
} from './Menus.types';

export const getMenuList = async (request: GetMenuListRequest) => {
    return await AxiosAPI.request<GetMenuListResponse>({
        method: REQUEST_METHOD.GET,
        url: `${ROUTES.MENUS}/${request.businessLocationId}`,
    });
};

export const updateMenuList = async (request: UpdateMenuListPayload) => {
    const response = await AxiosAPI.request<string>({
        method: REQUEST_METHOD.PATCH,
        url: `${ROUTES.MENUS}/type/${request.businessLocationId}`,
        data: request.payload,
    });

    return response;
};
