import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Col,
    Container,
    ErrorPage,
    LoadingOverlay,
    Row,
    showToast,
    Text,
} from 'spoton-lib';
import { isFulfilled } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

import { PreviousPageLink } from 'features/common/components/PreviousPageLink/PreviousPageLink.component';
import {
    ExternalLinkButton,
    getConfigVar,
    InfoTooltip,
    MenuDropdown,
    RoundedButton,
} from 'features/common';
import { useAppDispatch, useAppSelector } from 'api/store';
import {
    getMenuList,
    setIsInitialPageLoading,
    setLoading,
    updateMenuList,
} from './Menus.slice';
import { MenuResponse, PayloadType, SelectedMenuType } from './Menus.types';
import styles from './Menus.module.scss';
import { getStoreIntegrationStatus } from 'features/merchant_onboarding/MerchantOnboarding.slice';

interface IMenus {
    isModal?: boolean;
    clickedButton?: string;
    setClickedButton?: Dispatch<SetStateAction<string>>;
    setIsOpen?: Dispatch<SetStateAction<boolean>>;
    setMarkupPriceModal?: Dispatch<SetStateAction<boolean>>;
}

interface IMenus {
    isModal?: boolean;
    clickedButton?: string;
    setClickedButton?: Dispatch<SetStateAction<string>>;
    setIsOpen?: Dispatch<SetStateAction<boolean>>;
    setMarkupPriceModal?: Dispatch<SetStateAction<boolean>>;
}

export function Menus({
    isModal = false,
    clickedButton = '',
    setClickedButton = () => {},
    setIsOpen = () => {},
    setMarkupPriceModal = () => {},
}: IMenus) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { loading, isInitialPageLoading } = useAppSelector(
        (state) => state.menus,
    );

    const { businessLocationId, isExpressMerchant } = useAppSelector(
        (state) => state.merchantOnboarding,
    );

    const [menus, setMenus] = useState<MenuResponse[]>([]);
    const [isLoadingMenus, setIsLoadingMenus] = useState(false);

    const [selectedMenus, setSelectedMenus] = useState<SelectedMenuType>({
        storefront: '',
        marketplace: '',
    });

    const getMenus = async () => {
        if (businessLocationId) {
            setMenus([]);
            if (!isModal) {
                dispatch(setIsInitialPageLoading(true));
            }
            setIsLoadingMenus(true);
            const response = await dispatch(
                getMenuList({ businessLocationId }),
            );
            setIsLoadingMenus(false);

            if (!isModal) {
                dispatch(setIsInitialPageLoading(false));
            }
            if (response && isFulfilled(response)) {
                if (response.payload.status === 'success') {
                    setMenus(response.payload.data);

                    const getSelectedStoreFrontMenu =
                        response.payload.data.find((menu) =>
                            menu.type.includes('STOREFRONT'),
                        );
                    const getSelectedMarketPlaceMenu =
                        response.payload.data.find((menu) =>
                            menu.type.includes('MARKETPLACE'),
                        );
                    setSelectedMenus({
                        storefront: getSelectedStoreFrontMenu
                            ? getSelectedStoreFrontMenu.id
                            : '',
                        marketplace: getSelectedMarketPlaceMenu
                            ? getSelectedMarketPlaceMenu.id
                            : '',
                    });
                }
            }
        }
    };

    useEffect(() => {
        getMenus();
    }, [businessLocationId]);

    const handleSave = async () => {
        if (businessLocationId) {
            if (
                selectedMenus.storefront === '' &&
                selectedMenus.marketplace === ''
            ) {
                showToast({
                    content: 'Select menu for ordering/marketplace menu.',
                    containerId: 'toast-container',
                    variant: 'danger',
                    position: 'top-center',
                });
            } else {
                const payload: PayloadType = {
                    isRpos: !isExpressMerchant as boolean,
                    menus: [],
                    is_menus_confirmed:
                        clickedButton === 'Continue' ? true : false,
                };
                if (selectedMenus.storefront != '') {
                    payload.menus.push({
                        id: selectedMenus.storefront,
                        type: 'STOREFRONT',
                    });
                }

                if (selectedMenus.marketplace != '') {
                    payload.menus.push({
                        id: selectedMenus.marketplace,
                        type: 'MARKETPLACE',
                    });
                }

                dispatch(setLoading(true));
                const response = await dispatch(
                    updateMenuList({
                        businessLocationId,
                        payload,
                    }),
                );

                dispatch(setLoading(false));

                if (response && isFulfilled(response)) {
                    if (response.payload.status === 'success') {
                        if (!isModal) {
                            navigate('/doordash');
                        }
                        if (isModal) {
                            setClickedButton('');
                            setIsOpen(false);
                            if (clickedButton === 'Continue') {
                                setMarkupPriceModal(true);
                            }
                            await dispatch(
                                getStoreIntegrationStatus({
                                    businessLocationId,
                                }),
                            );
                        }
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (
            isModal &&
            (clickedButton === 'Continue' || clickedButton === 'Save')
        ) {
            setClickedButton('');
            handleSave();
        }
    }, [clickedButton]);

    if (!isModal && isInitialPageLoading) {
        return <LoadingOverlay />;
    }

    if (!isModal && menus.length <= 1 && isExpressMerchant) {
        return <ErrorPage errorCode="404" />;
    }

    // useEffect(() => {
    //     const getSelectedStoreFrontMenu = menus.find((menu) =>
    //         menu.type.includes('STOREFRONT'),
    //     );
    //     const getSelectedMarketPlaceMenu = menus.find((menu) =>
    //         menu.type.includes('MARKETPLACE'),
    //     );
    //     setSelectedMenus({
    //         storefront: getSelectedStoreFrontMenu
    //             ? getSelectedStoreFrontMenu.id
    //             : '',
    //         marketplace: getSelectedMarketPlaceMenu
    //             ? getSelectedMarketPlaceMenu.id
    //             : '',
    //     });
    // }, []);

    return (
        <>
            <Container
                className={`${styles.Menus} ${isModal ? '' : styles.Menus_pageContainer}`}
                fluid
            >
                {!isModal && (
                    <>
                        <PreviousPageLink
                            title="Previous page"
                            hyperlink="/doordash"
                        />
                        <Row debug className={styles.Menus_row}>
                            <Col
                                xs={12}
                                md={8}
                                className={styles.Menus_col}
                                debug
                            >
                                <span
                                    style={{ display: 'flex', gap: '1.5rem' }}
                                >
                                    <Text as="h1">Menus</Text>
                                    {!isExpressMerchant && (
                                        <ExternalLinkButton
                                            linkText="Open Back of House"
                                            onClick={() => {
                                                window.open(
                                                    getConfigVar(
                                                        'REACT_APP_BACK_OF_HOUSE_URL',
                                                    ),
                                                );
                                            }}
                                        />
                                    )}
                                </span>
                            </Col>
                            <Col
                                xs={12}
                                md={2}
                                offset={{ md: 1 }}
                                className={styles.Menus_col}
                                debug
                            >
                                <RoundedButton
                                    buttonText="Save"
                                    onClick={handleSave}
                                    isLoading={loading}
                                    width={162}
                                />
                            </Col>
                        </Row>

                        <Row debug className={styles.Menus_row}>
                            <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                className={styles.Menus_col}
                            >
                                <p>
                                    Any changes made to your menu in the future
                                    will automatically sync to DoorDash.
                                </p>
                            </Col>
                        </Row>
                    </>
                )}

                <Row debug className={styles.Menus_row}>
                    <Col
                        debug
                        className={`${styles.Menus_col} ${isModal ? styles.Menus_modalCol : ''}`}
                        xs={12}
                        sm={12}
                        md={isModal ? 12 : 6}
                        lg={isModal ? 12 : 6}
                        xl={isModal ? 12 : 4}
                    >
                        <MenuDropdown
                            heading={
                                !isModal ? (
                                    <>
                                        DoorDash Online Ordering Menu{' '}
                                        <InfoTooltip tooltipContent="Your own online ordering website powered by DoorDash" />
                                    </>
                                ) : (
                                    <></>
                                )
                            }
                            label={
                                isModal ? (
                                    <>
                                        Select a menu for DoorDash Online
                                        Ordering
                                        <InfoTooltip tooltipContent="Your own online ordering website powered by DoorDash" />
                                    </>
                                ) : (
                                    'Select a menu'
                                )
                            }
                            placeholder="Select a menu"
                            keyName="storefront"
                            value={selectedMenus}
                            setValue={setSelectedMenus}
                            list={menus}
                            isModal={isModal}
                            isLoading={isLoadingMenus}
                        />
                    </Col>
                    <Col
                        debug
                        className={`${styles.Menus_col} ${isModal ? styles.Menus_modalCol : ''}`}
                        xs={12}
                        sm={12}
                        md={isModal ? 12 : 6}
                        lg={isModal ? 12 : 6}
                        xl={isModal ? 12 : 4}
                    >
                        <MenuDropdown
                            heading={
                                !isModal ? (
                                    <>
                                        DoorDash Marketplace Menu{' '}
                                        <InfoTooltip tooltipContent="Restaurant listing on DoorDash.com and the DoorDash app" />
                                    </>
                                ) : (
                                    ''
                                )
                            }
                            label={
                                isModal ? (
                                    <>
                                        Select a menu for DoorDash Marketplace{' '}
                                        <InfoTooltip tooltipContent="Restaurant listing on DoorDash.com and the DoorDash app" />
                                    </>
                                ) : (
                                    'Select a menu'
                                )
                            }
                            placeholder="Select a menu"
                            keyName="marketplace"
                            value={selectedMenus}
                            setValue={setSelectedMenus}
                            list={menus}
                            isModal={isModal}
                            isLoading={isLoadingMenus}
                        />
                    </Col>
                </Row>

                {isModal && (
                    <Row debug className={styles.Menus_row}>
                        <Col
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={`${styles.Menus_col} ${isModal ? styles.Menus_modalCol : ''}`}
                        >
                            <p>
                                Note : any changes made to your menu in the
                                future will automatically sync to DoorDash.
                            </p>
                        </Col>
                    </Row>
                )}
            </Container>
        </>
    );
}
