// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoTooltip_InfoTooltip_tooltip__X4-MG{max-width:25.9375rem;background-color:#414967;border-color:#414967}.InfoTooltip_InfoTooltip_tooltip_wrapper__AHO38{display:flex;color:#7d89a3}`, "",{"version":3,"sources":["webpack://./src/features/common/components/InfoTooltip/InfoTooltip.module.scss"],"names":[],"mappings":"AAEI,wCACI,oBAAA,CACA,wBAAA,CACA,oBAAA,CAEA,gDACI,YAAA,CACA,aAAA","sourcesContent":[".InfoTooltip {\n    /* stylelint-disable */\n    &_tooltip {\n        max-width: 25.9375rem;\n        background-color: #414967;\n        border-color: #414967;\n\n        &_wrapper {\n            display: flex;\n            color: #7d89a3;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InfoTooltip_tooltip": `InfoTooltip_InfoTooltip_tooltip__X4-MG`,
	"InfoTooltip_tooltip_wrapper": `InfoTooltip_InfoTooltip_tooltip_wrapper__AHO38`
};
export default ___CSS_LOADER_EXPORT___;
