// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BusinessHoursModal_BusinessHoursModal_businessHoursPageLink__kmPR7{gap:.5rem;margin:0;margin:initial;margin-top:.6875rem;font-weight:500;font-size:1rem !important;line-height:1.5rem;text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/features/merchant_onboarding/components/business_hours_modal/BusinessHoursModal.module.scss"],"names":[],"mappings":"AACI,oEACI,SAAA,CACA,QAAA,CAAA,cAAA,CACA,mBAAA,CACA,eAAA,CACA,yBAAA,CACA,kBAAA,CACA,oBAAA","sourcesContent":[".BusinessHoursModal {\n    &_businessHoursPageLink {\n        gap: 0.5rem;\n        margin: unset;\n        margin-top: 0.6875rem;\n        font-weight: 500;\n        font-size: 1rem !important;\n        line-height: 1.5rem;\n        text-decoration: none;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BusinessHoursModal_businessHoursPageLink": `BusinessHoursModal_BusinessHoursModal_businessHoursPageLink__kmPR7`
};
export default ___CSS_LOADER_EXPORT___;
