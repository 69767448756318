// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrevousPageLink_PreviousPageLink__vOAsz{gap:.5rem;margin-bottom:1.5rem;color:#1c5dcd;font-weight:500}`, "",{"version":3,"sources":["webpack://./src/features/common/components/PreviousPageLink/PrevousPageLink.module.scss"],"names":[],"mappings":"AAMA,yCACI,SAAA,CACA,oBAAA,CACA,aAAA,CACA,eAAA","sourcesContent":["@import 'spoton-lib/styles/responsive';\n\n$tablet: get-breakpoint('tablet');\n$desktop: get-breakpoint('desktop');\n$mobile: get-breakpoint('mobile');\n\n.PreviousPageLink {\n    gap: 0.5rem;\n    margin-bottom: 1.5rem;\n    color: #1c5dcd;\n    font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PreviousPageLink": `PrevousPageLink_PreviousPageLink__vOAsz`
};
export default ___CSS_LOADER_EXPORT___;
