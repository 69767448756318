import React from 'react';
import { Hyperlink, Icon, Text } from 'spoton-lib';
import { isFulfilled } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'api/store';
import { getBusinessHoursUrl, ReusableModal } from 'features/common';
import styles from './BusinessHoursModal.module.scss';
import { businessHoursConfirmation } from 'features/merchant_onboarding/MerchantOnboarding.slice';
import { BusinessHoursExistStatus } from 'features/merchant_onboarding/MerchantOnboarding.types';

interface IBusinessHoursModal {
    isBusinessHoursModalOpen: boolean;
    setIsBusinessHoursModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsConnectDoordashModalOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setIsUserTriesToSetBusinessHours: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

export function BusinessHoursModal(props: IBusinessHoursModal) {
    const {
        isBusinessHoursModalOpen,
        setIsBusinessHoursModalOpen,
        setIsConnectDoordashModalOpen,
        setIsUserTriesToSetBusinessHours,
    } = props;
    const dispatch = useAppDispatch();

    const {
        isBusinessHoursExist,
        businessLocationId,
        loading,
        isExpressMerchant,
    } = useAppSelector((state) => state.merchantOnboarding);

    const onConfirm = async () => {
        if (
            isBusinessHoursExist === BusinessHoursExistStatus.UPDATED &&
            businessLocationId
        ) {
            const response = await dispatch(
                businessHoursConfirmation({
                    isBusinessHoursUpdated: true,
                    locationId: businessLocationId,
                }),
            );
            if (response && isFulfilled(response)) {
                if (response.payload.status === 'success') {
                    setIsBusinessHoursModalOpen(false);
                    setIsConnectDoordashModalOpen(true);
                }
            }
        } else {
            setIsUserTriesToSetBusinessHours(true);
            window.open(
                getBusinessHoursUrl(Boolean(isExpressMerchant)),
                '_blank',
            );
        }
    };
    return (
        <ReusableModal
            isOpen={isBusinessHoursModalOpen}
            title={
                isBusinessHoursExist === BusinessHoursExistStatus.UPDATED
                    ? 'Confirm your business hours'
                    : 'We are missing your business hours'
            }
            confirmText={
                isBusinessHoursExist === BusinessHoursExistStatus.UPDATED
                    ? 'I’ve confirmed my hours'
                    : 'Set up hours'
            }
            isConfirmBtnLoading={loading}
            onConfirm={onConfirm}
            onCancel={() => setIsBusinessHoursModalOpen(false)}
        >
            {isBusinessHoursExist === BusinessHoursExistStatus.UPDATED ? (
                <>
                    <Text>
                        We need to make sure your business hours are up to date
                        before proceeding. Confirm they are correct and then
                        continue.
                    </Text>
                    <Hyperlink
                        className={
                            styles.BusinessHoursModal_businessHoursPageLink
                        }
                        target="_blank"
                        color="#1C5DCD"
                        href={getBusinessHoursUrl(Boolean(isExpressMerchant))}
                    >
                        View Business Hours
                        <Icon size={18} name="ExternalLinkIcon" />
                    </Hyperlink>
                </>
            ) : (
                <Text>
                    We can’t set up DoorDash without your business hours. Can
                    you add them now?
                </Text>
            )}
        </ReusableModal>
    );
}
