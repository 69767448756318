import React, { useEffect, useState } from 'react';
import { Dropdown } from 'spoton-lib';

import styles from './MenuDropdown.module.scss';

type ListType = {
    id: string;
    name: string;
    type: string[];
};

type MenuDropdownType = {
    heading: React.ReactNode | string;
    label: React.ReactNode | string;
    placeholder: string;
    keyName: string;
    value: any;
    setValue: any;
    list: ListType[];
    isModal?: boolean;
    isLoading?: boolean;
};

type OptionType = {
    value: string;
    label: string;
};

export const MenuDropdown = (props: MenuDropdownType) => {
    const {
        keyName,
        heading,
        label,
        placeholder,
        setValue,
        value,
        list,
        isModal,
        isLoading = false,
    } = props;

    const [selected, setSelected] = useState<OptionType>();

    const handleChange = (option: OptionType): void => {
        setValue({ ...value, [keyName]: option.value });
    };

    useEffect(() => {
        if (value[keyName] !== '') {
            const key = list.find((item) => item.id === value[keyName]);
            if (key) {
                const keyData = {
                    label: key.name,
                    value: key.id,
                };
                setSelected(keyData);
            }
        }
    }, [value]);

    return (
        <div className={`${styles.Menus} ${isModal ? '' : styles.Menus_page}`}>
            {heading && <h2>{heading}</h2>}

            <span>
                <h5>{label}</h5>
                <Dropdown
                    isLoading={isLoading}
                    className={styles.MenuDropdown}
                    placeholder={placeholder}
                    isSearchable={false}
                    onChange={(option) => handleChange(option as OptionType)}
                    options={
                        list.length > 0
                            ? list.map((val: any) => {
                                  return { label: val.name, value: val.id };
                              })
                            : []
                    }
                    value={selected}
                    styles={{
                        menu: (provided) => ({
                            ...provided,
                        }),
                        menuPortal: (base) => ({
                            ...base,
                            zIndex: 100,
                        }),
                    }}
                    menuPortalTarget={document.body}
                />
            </span>
        </div>
    );
};
