import React from 'react';

export function ConnectionIcon() {
    return (
        <svg
            width="24"
            height="60"
            viewBox="0 0 24 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ alignSelf: 'center' }}
        >
            <line
                x1="12.75"
                y1="-3.27835e-08"
                x2="12.75"
                y2="60"
                stroke="#DDE3EA"
                strokeWidth="1.5"
                strokeDasharray="4 4"
            />
            <path
                d="M0 30C0 23.3726 5.37258 18 12 18C18.6274 18 24 23.3726 24 30C24 36.6274 18.6274 42 12 42C5.37258 42 0 36.6274 0 30Z"
                fill="#EAEEF3"
            />
            <path
                d="M10.5007 31.5003C10.7451 31.7497 11.0368 31.9479 11.3588 32.0832C11.6807 32.2185 12.0265 32.2882 12.3757 32.2882C12.7249 32.2882 13.0706 32.2185 13.3926 32.0832C13.7146 31.9479 14.0063 31.7497 14.2507 31.5003L17.2507 28.5003C17.748 28.003 18.0273 27.3285 18.0273 26.6253C18.0273 25.922 17.748 25.2476 17.2507 24.7503C16.7534 24.253 16.079 23.9736 15.3757 23.9736C14.6724 23.9736 13.998 24.253 13.5007 24.7503L13.1257 25.1253M13.5003 28.4733C13.2559 28.2239 12.9641 28.0257 12.6422 27.8904C12.3202 27.7552 11.9745 27.6855 11.6253 27.6855C11.2761 27.6855 10.9303 27.7552 10.6084 27.8904C10.2864 28.0257 9.99469 28.2239 9.75028 28.4733L6.75028 31.4733C6.253 31.9706 5.97363 32.6451 5.97363 33.3483C5.97363 34.0516 6.253 34.7261 6.75028 35.2233C7.24756 35.7206 7.92202 36 8.62528 36C9.32854 36 10.003 35.7206 10.5003 35.2233L10.8753 34.8483"
                stroke="#0F1221"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
