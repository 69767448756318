// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConnectDoordashModal_ConnectDoordashmodal_signupLink__HI7se{display:inline;color:#0f1221;font-size:1rem !important}`, "",{"version":3,"sources":["webpack://./src/features/merchant_onboarding/components/connect_doordash_modal/ConnectDoordashModal.module.scss"],"names":[],"mappings":"AACI,6DACI,cAAA,CACA,aAAA,CACA,yBAAA","sourcesContent":[".ConnectDoordashmodal {\n    &_signupLink {\n        display: inline;\n        color: #0f1221;\n        font-size: 1rem !important;\n\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConnectDoordashmodal_signupLink": `ConnectDoordashModal_ConnectDoordashmodal_signupLink__HI7se`
};
export default ___CSS_LOADER_EXPORT___;
