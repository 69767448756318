import {
    ActivateIntegrationRequest,
    ActivateIntegrationResponse,
    BusinessHoursConfirmationRequest,
    GetStoreListRequest,
    GetStoreListResponse,
    InitializeOnboardingRequest,
    InitializeOnboardingResponse,
    MenuStatusRequest,
    MenuStatusResponse,
    MenusRequest,
    MenusResponse,
    RemoveSmartButtonModalRequest,
    RemoveSmartButtonModalResponse,
    RestartProcessRequest,
    RestartProcessResponse,
    SaveSelectedLocationRequest,
    SaveSelectedLocationResponse,
    SignupUrlRequest,
    SignupUrlResponse,
    StoreIntegrationStatusRequest,
    StoreIntegrationStatusResponse,
    StorePauseRequest,
    StorePauseResponse,
    getBusinessHoursStatusRequest,
    getBusinessHoursStatusResponse,
} from 'features/merchant_onboarding/MerchantOnboarding.types';
import { AxiosAPI } from 'utils/axios';
import { REQUEST_METHOD } from 'utils/constants';
import { ROUTES } from 'utils/endpoints';

export const getSignupUrl = async (request: SignupUrlRequest) => {
    return await AxiosAPI.request<SignupUrlResponse>({
        method: REQUEST_METHOD.GET,
        url: `${ROUTES.SIGNUP_URL}/${request.businessLocationId}`,
    });
};

export const getStoreList = async (request: GetStoreListRequest) => {
    return await AxiosAPI.request<GetStoreListResponse>({
        method: REQUEST_METHOD.GET,
        url: `${ROUTES.STORE_LIST}/${request.businessLocationId}/${request.exchange_code}/${request.pageIndex}/${request.pageSize}`,
    });
};

export const activateIntegration = async (
    request: ActivateIntegrationRequest,
) => {
    return await AxiosAPI.request<ActivateIntegrationResponse>({
        method: REQUEST_METHOD.GET,
        url: `${ROUTES.ACTIVATE_INTEGRATION}/${request.onboarding_id}`,
    });
};

export const initializeOnboarding = async (
    request: InitializeOnboardingRequest,
) => {
    return await AxiosAPI.request<InitializeOnboardingResponse>({
        method: REQUEST_METHOD.POST,
        url: ROUTES.INITIALIZE_ONBOARDING,
        data: request,
    });
};

export const getMenuStatus = async (request: MenuStatusRequest) => {
    return await AxiosAPI.request<MenuStatusResponse>({
        method: REQUEST_METHOD.GET,
        url: `${ROUTES.MENU_STATUS}/${request.onboardingId}`,
    });
};

export const getStoreIntegrationStatus = async (
    request: StoreIntegrationStatusRequest,
) => {
    return await AxiosAPI.request<StoreIntegrationStatusResponse>({
        method: REQUEST_METHOD.GET,
        url: `${ROUTES.STORE_INTEGRATION_STATUS}/${request.businessLocationId}`,
    });
};

export const menuRequest = async (request: MenusRequest) => {
    return await AxiosAPI.request<MenusResponse>({
        method: REQUEST_METHOD.GET,
        url: `${ROUTES.MENU_REQUEST}/${request.businessLocationId}`,
    });
};

export const storePause = async (request: StorePauseRequest) => {
    const { businessLocationId, ...data } = request;

    return await AxiosAPI.request<StorePauseResponse>({
        method: REQUEST_METHOD.PUT,
        url: `${ROUTES.STORE_PAUSE}/${businessLocationId}`,
        data,
    });
};

export const restartProcess = async (request: RestartProcessRequest) => {
    return await AxiosAPI.request<RestartProcessResponse>({
        method: REQUEST_METHOD.PUT,
        url: `${ROUTES.RESTART_PROCESS}/${request.businessLocationId}`,
    });
};

export const saveSelectedLocation = async (
    request: SaveSelectedLocationRequest,
) => {
    return await AxiosAPI.request<SaveSelectedLocationResponse>({
        method: REQUEST_METHOD.PATCH,
        url: ROUTES.SAVE_SELECTED_LOCATION,
        data: request,
    });
};

export const removeSmartButtonModal = async (
    request: RemoveSmartButtonModalRequest,
) => {
    return await AxiosAPI.request<RemoveSmartButtonModalResponse>({
        method: REQUEST_METHOD.PATCH,
        url: `${ROUTES.SMART_BUTTON_MODAL_REMOVE}/${request.businessLocationId}`,
        data: {
            is_model_closed: request.is_model_closed,
        },
    });
};

export const getBusinessHoursStatus = async (
    request: getBusinessHoursStatusRequest,
) => {
    return await AxiosAPI.request<getBusinessHoursStatusResponse>({
        method: REQUEST_METHOD.GET,
        url: `${ROUTES.GET_BUSINESS_HOURS_STATUS}/${request.businessLocationId}`,
    });
};

export const businessHoursConfirmation = async (
    request: BusinessHoursConfirmationRequest,
) => {
    return await AxiosAPI.request<string>({
        method: REQUEST_METHOD.PATCH,
        url: ROUTES.BUSINESS_HOURS_CONFIRMATION,
        data: request,
    });
};
