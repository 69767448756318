import React, { useEffect, useState } from 'react';
import { Hyperlink, Icon, Text } from 'spoton-lib';

import { useAppSelector } from 'api/store';
import { MenuModalTitle } from '../../MerchantOnboarding.utility';
import { ReusableModal } from 'features/common';
import { URL } from 'utils/constants';
import {
    MenuStatus,
    OnboardingStatus as OnboardingStatusEnum,
} from 'features/merchant_onboarding/MerchantOnboarding.types';
import styles from './OnboardingStatus.module.scss';
import { MenuPreviewLinkInfo } from './OnboardingStatus.types';

interface IOnboardingStatus {
    isOnboardingStatusModalOpen: boolean;
    setIsOnboardingStatusModalOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setIsActivationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClickTryAgainBtn: () => void;
}

const defaultMenuPreviewLinkInfo: MenuPreviewLinkInfo[] = [
    {
        id: 'STOREFRONT',
        linkText: 'View DoorDash Online Ordering Menu',
    },
    {
        id: 'MARKETPLACE',
        linkText: 'View Marketplace Menu',
    },
];

export function OnboardingStatus(props: IOnboardingStatus) {
    const {
        isOnboardingStatusModalOpen,
        setIsOnboardingStatusModalOpen,
        setIsActivationModalOpen,
        onClickTryAgainBtn,
    } = props;

    const [menusPreviewLinkInfo, setMenusPreviewLinkInfo] = useState<
        MenuPreviewLinkInfo[]
    >(defaultMenuPreviewLinkInfo);

    const { menuStatus, onboardingStatus } = useAppSelector(
        (state) => state.merchantOnboarding,
    );

    useEffect(() => {
        if (menuStatus && menuStatus?.menus.length > 0) {
            setMenusPreviewLinkInfo((prevState) => {
                return prevState.map((menuPreviewLinkInfo) => {
                    const getMenuByType = menuStatus.menus.find(
                        (menu) => menu.type === menuPreviewLinkInfo.id,
                    );

                    if (getMenuByType) {
                        return {
                            ...menuPreviewLinkInfo,
                            link: getMenuByType.menu_preview_link,
                        };
                    } else {
                        return menuPreviewLinkInfo;
                    }
                });
            });
        }
    }, [menuStatus]);

    const content = (status: MenuStatus | undefined) => {
        switch (status) {
            case MenuStatus.SUCCESS:
                return (
                    <>
                        <Text>
                            Give it a quick look to make sure everything&apos;s
                            spot on.
                        </Text>
                        <ul
                            style={{
                                paddingInlineStart: '1.5625rem',
                            }}
                        >
                            <li>
                                Photos can take up to 72 hours to be processed
                                by DoorDash.
                            </li>
                            <li>
                                Any changes made to your menu in the future will
                                automatically sync to DoorDash.
                            </li>
                        </ul>
                        {menusPreviewLinkInfo.map(
                            (menuPreviewLinkInfo) =>
                                menuPreviewLinkInfo.link && (
                                    <Hyperlink
                                        key={menuPreviewLinkInfo.id}
                                        size={16}
                                        className={
                                            styles.OnboardingStatus_doorDashMenuLink
                                        }
                                        href={menuPreviewLinkInfo.link}
                                        target="_blank"
                                        color="#1C5DCD"
                                    >
                                        {menuPreviewLinkInfo.linkText}{' '}
                                        <Icon
                                            size={18}
                                            name="ExternalLinkIcon"
                                        />
                                    </Hyperlink>
                                ),
                        )}
                    </>
                );
            case MenuStatus.FAILED:
                return (
                    <>
                        <Icon
                            name="DangerSolidIconTriangle"
                            className={
                                styles.OnboardingStatus_dangerSolidIconTriangle
                            }
                            size={45}
                        />
                        <Text>
                            We were unable to sync your menu. Please try again
                            later. If the error persists
                            <Hyperlink
                                style={{
                                    fontSize: '1rem',
                                    color: '#000000',
                                    fontWeight: '500',
                                }}
                                href={URL.DOORDASH_CONTACT_SUPPORT_URL}
                                target="_blank"
                            >
                                contact support
                            </Hyperlink>
                        </Text>
                    </>
                );
            default:
                return (
                    <>
                        <Text>This may take a few minutes.</Text>
                        <Text style={{ paddingBottom: '1.5rem' }}>
                            If you’re waiting for more than 10 minutes,
                            <Hyperlink
                                style={{
                                    fontSize: '1rem',
                                    color: '#000000',
                                }}
                                href={URL.DOORDASH_CONTACT_SUPPORT_URL}
                                target="_blank"
                            >
                                contact support
                            </Hyperlink>
                        </Text>
                    </>
                );
        }
    };

    const onConfirm = (status: MenuStatus | undefined) => {
        if (status === MenuStatus.SUCCESS) {
            setIsOnboardingStatusModalOpen(false);
            setIsActivationModalOpen(true);
        } else {
            onClickTryAgainBtn();
        }
    };

    return (
        <ReusableModal
            isOpen={isOnboardingStatusModalOpen}
            title={MenuModalTitle(menuStatus?.status)}
            onCancel={() => setIsOnboardingStatusModalOpen(false)}
            onConfirm={() => onConfirm(menuStatus?.status)}
            confirmText={
                menuStatus?.status === MenuStatus.FAILED
                    ? 'Try again'
                    : 'Continue'
            }
            showCancelBtn={
                onboardingStatus === OnboardingStatusEnum.MENU_REQUESTED
                    ? false
                    : true
            }
            showFooter={
                menuStatus?.status === MenuStatus.SUCCESS ||
                (menuStatus?.status === MenuStatus.FAILED &&
                    onboardingStatus === 'MENU_BLOCK')
            }
            showLoadingIndicator={
                onboardingStatus === OnboardingStatusEnum.MENU_REQUESTED
                    ? true
                    : false
            }
            gap={menuStatus?.status === MenuStatus.FAILED ? '1.875rem' : '0rem'}
            className={styles.OnboardingStatus_modal}
        >
            {content(menuStatus?.status)}
        </ReusableModal>
    );
}
