export interface IMenusSliceState {
    loading: boolean;
    isInitialPageLoading: boolean;
    isQueuedMenuUpdate: boolean;
    menus: MenuResponse[];
}

export type GetMenuListRequest = {
    businessLocationId: string;
};

export enum MenuType {
    STOREFRONT = 'STOREFRONT',
    MARKETPLACE = 'MARKETPLACE',
}

export type MenuResponse = {
    id: string;
    name: string;
    type: MenuType[];
};

export type GetMenuListResponse = {
    menuList: MenuResponse[];
    isQueuedMenuUpdate: boolean;
};

export type UpdateMenuListRequest = {
    id: string;
    type: string;
};

export type PayloadType = {
    isRpos: boolean;
    menus: UpdateMenuListRequest[];
    is_menus_confirmed: boolean;
};

export type UpdateMenuListPayload = {
    businessLocationId: string;
    payload: PayloadType;
};

export type SelectedMenuType = {
    storefront: string;
    marketplace: string;
};
