import { AxiosAPI } from 'utils/axios';
import { REQUEST_METHOD } from 'utils/constants';
import { ROUTES } from 'utils/endpoints';
import {
    GetMarkupPriceRequest,
    GetMarkupPriceResponse,
    SetMarkupPriceRequest,
} from './PriceMarkup.types';

export const getMarkupPrice = async (request: GetMarkupPriceRequest) => {
    return await AxiosAPI.request<GetMarkupPriceResponse>({
        method: REQUEST_METHOD.GET,
        url: `${ROUTES.MARKUP_PRICE}/${request.businessLocationId}`,
    });
};

export const setMarkupPrice = async (request: SetMarkupPriceRequest) => {
    const response = await AxiosAPI.request<string>({
        method: REQUEST_METHOD.PATCH,
        url: ROUTES.MARKUP_PRICE,
        data: request,
    });

    return response;
};
