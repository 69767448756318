import React from 'react';
import { ErrorPage } from 'spoton-lib';

import { IPropTypes, IState } from './ErrorBoundaryGeneric.types';

// NOTE: using class based component ONLY because we need support for error boundaries
export class ErrorBoundaryGeneric extends React.Component<IPropTypes> {
    state: IState = { error: null };

    componentDidCatch(error: Error): void {
        this.setState({ error });
    }

    render(): React.ReactNode {
        if (this.state.error) {
            return <ErrorPage errorCode={'500'} />;
        }

        return this.props.children;
    }
}
