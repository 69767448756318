import React from 'react';
import {
    Navigate,
    Route,
    createBrowserRouter,
    createRoutesFromElements,
} from 'react-router-dom';
import { ErrorPage } from 'spoton-lib';

import { AppLayout } from '../components/AppLayout';
import { MerchantOnboarding, PriceMarkup, Menus } from 'features';

/**
 * Prefix all routes if using a MFE, so that we get the correct routing from
 * our root application.
 *
 * The root application will mount the application to a specific route, in
 * those cases we will need to add a prefix so that the routes in this
 * application continue to match.
 */

export const routes = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/">
            <Route index element={<Navigate to="/doordash" replace />} />
            <Route path="/doordash/" element={<AppLayout />}>
                <Route path="" element={<MerchantOnboarding />} />
                <Route path="price-markup" element={<PriceMarkup />} />
                <Route path="menus" element={<Menus />} />
                <Route path="*" element={<ErrorPage errorCode="404" />} />
            </Route>
        </Route>,
    ),
);
