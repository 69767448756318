import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
    GetMenuListRequest,
    IMenusSliceState,
    UpdateMenuListPayload,
} from './Menus.types';
import * as API from './Menus.api';

export const initialState: IMenusSliceState = {
    loading: false,
    isInitialPageLoading: true,
    isQueuedMenuUpdate: false,
    menus: [],
};

export const menusSlice = createSlice({
    name: 'menus',
    initialState,
    reducers: {
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setIsInitialPageLoading: (
            state,
            { payload }: PayloadAction<boolean>,
        ) => {
            state.isInitialPageLoading = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMenuList.fulfilled, (state, { payload }) => {
            state.isQueuedMenuUpdate = payload.data.isQueuedMenuUpdate;
            state.menus = payload.data.menuList;
        });
    },
});

export const { setLoading, setIsInitialPageLoading } = menusSlice.actions;

export const getMenuList = createAsyncThunk(
    'menus/getMenuList',
    async (request: GetMenuListRequest, { rejectWithValue }) => {
        const response = await API.getMenuList(request);
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);

export const updateMenuList = createAsyncThunk(
    'menus/updateMenuList',
    async (request: UpdateMenuListPayload, { rejectWithValue }) => {
        const response = await API.updateMenuList(request);
        if (response.status === 'success') {
            return response;
        } else {
            return rejectWithValue(response.errors);
        }
    },
);
