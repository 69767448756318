import React from 'react';
import { Text } from 'spoton-lib';

import { ReusableModal } from 'features/common';
import { URL } from 'utils/constants';

interface ISuccessModal {
    isSuccessModalOpen: boolean;
    setIsSuccessModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SuccessModal(props: ISuccessModal) {
    const { isSuccessModalOpen, setIsSuccessModalOpen } = props;

    const onClickScheduleCallButton = () => {
        window.open(URL.DOORDASH_BOOK_CALL_URL);
        setIsSuccessModalOpen(false);
    };
    return (
        <ReusableModal
            isOpen={isSuccessModalOpen}
            title="DoorDash is live! There's one final step"
            onCancel={() => setIsSuccessModalOpen(false)}
            confirmText="Schedule call"
            cancelText="Later"
            onConfirm={onClickScheduleCallButton}
        >
            <Text>
                Whats next? Set up a call with DoorDash to add a Storefront link
                or smart “Order Now” button to your website.
            </Text>
        </ReusableModal>
    );
}
