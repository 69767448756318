import { combineReducers } from '@reduxjs/toolkit';

import { merchantOnboardingSlice } from 'features/merchant_onboarding/MerchantOnboarding.slice';
import { markupPriceSlice } from 'features/price-markup/PriceMarkup.slice';
import { menusSlice } from 'features/menus/Menus.slice';

export const rootReducer = combineReducers({
    merchantOnboarding: merchantOnboardingSlice.reducer,
    markupPrice: markupPriceSlice.reducer,
    menus: menusSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
