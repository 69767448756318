import React from 'react';

export function SpotOnLogo() {
    return (
        <svg
            width="81"
            height="24"
            viewBox="0 0 81 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27.9634 14.9936L29.66 14.0336C30.0558 15.05 30.8476 15.7277 32.2614 15.7277C33.5904 15.7277 34.0711 15.163 34.0711 14.57C34.0711 13.7512 33.3076 13.4406 31.6393 12.9606C29.9144 12.4806 28.2461 11.7465 28.2461 9.65711C28.2461 7.56769 30.0276 6.35358 31.9504 6.35358C33.7883 6.35358 35.2305 7.28534 35.9657 8.83828L34.0994 9.91122C33.7035 9.06416 33.0814 8.49946 31.9504 8.49946C31.0172 8.49946 30.48 8.97946 30.48 9.60064C30.48 10.2783 30.9041 10.6453 32.629 11.1536C34.4104 11.7183 36.305 12.283 36.305 14.5418C36.305 16.603 34.6084 17.8736 32.2049 17.8736C29.9993 17.8736 28.5289 16.8853 27.8785 15.3606C27.7654 15.2195 27.822 15.05 27.9634 14.9936Z"
                fill="#0F1221"
            />
            <path
                d="M45.6928 13.5804C45.6928 15.9804 43.9113 17.8157 41.7623 17.8157C40.5747 17.8157 39.7264 17.3922 39.1609 16.6299V20.7804H37.0967V9.85339C37.0967 9.68398 37.2098 9.57104 37.3794 9.57104H38.9629L39.1891 10.531C39.7547 9.79692 40.6313 9.34515 41.7906 9.34515C43.9113 9.34515 45.6928 11.1804 45.6928 13.5804ZM43.6003 13.5804C43.6003 12.2534 42.6389 11.3499 41.3664 11.3499C40.094 11.3499 39.1326 12.2534 39.1326 13.5804C39.1326 14.9075 40.094 15.811 41.3664 15.811C42.6672 15.811 43.6003 14.9075 43.6003 13.5804Z"
                fill="#0F1221"
            />
            <path
                d="M46.1738 13.5235C46.1738 11.1235 48.0684 9.25995 50.4153 9.25995C52.7906 9.25995 54.6851 11.1235 54.6851 13.5235C54.6851 15.9235 52.7906 17.787 50.4153 17.787C48.0684 17.7588 46.1738 15.9235 46.1738 13.5235ZM52.6492 13.5235C52.6492 12.2247 51.6878 11.2929 50.4153 11.2929C49.1711 11.2929 48.1815 12.2247 48.1815 13.5235C48.1815 14.8223 49.1429 15.7541 50.4153 15.7541C51.6878 15.7541 52.6492 14.8223 52.6492 13.5235Z"
                fill="#0F1221"
            />
            <path
                d="M58.1626 14.8801V11.5483L59.3785 11.3507C59.435 10.7295 59.5481 10.1083 59.7178 9.54361H58.1626V7.28479L56.2963 7.8495C56.1832 7.87773 56.0984 7.99067 56.0984 8.13185V9.54361H54.9673C54.7977 9.54361 54.6846 9.65655 54.6846 9.82597V11.5201H56.0701V14.8518C56.0701 17.026 57.0598 17.873 59.9723 17.5624V15.6707C58.7847 15.7836 58.1626 15.7554 58.1626 14.8801Z"
                fill="#0F1221"
            />
            <path
                d="M60.623 12.0002C60.623 8.69669 63.2245 6.15552 66.4763 6.15552C69.7281 6.15552 72.3296 8.69669 72.3296 12.0002C72.3296 15.2755 69.7281 17.8449 66.4763 17.8449C63.2245 17.8449 60.623 15.2755 60.623 12.0002ZM70.0957 12.0002C70.0957 9.85434 68.5122 8.35787 66.448 8.35787C64.4121 8.35787 62.8003 9.85434 62.8003 12.0002C62.8003 14.1179 64.3838 15.6143 66.448 15.6143C68.5122 15.6143 70.0957 14.1179 70.0957 12.0002Z"
                fill="#0F1221"
            />
            <path
                d="M80.7276 12.6769V17.6181H78.6634V12.931C78.6634 11.8299 78.0131 11.2652 77.0799 11.2652C76.062 11.2652 75.2985 11.8581 75.2985 13.2699V17.6181H73.2061V9.85339C73.2061 9.68398 73.3192 9.57104 73.4888 9.57104H75.0723L75.2985 10.4746C75.7792 9.74045 76.6275 9.34515 77.702 9.34515C79.3986 9.34515 80.7276 10.531 80.7276 12.6769Z"
                fill="#0F1221"
            />
            <path
                d="M11.9999 0C5.36468 0 0 5.22353 0 12C0 18.8047 5.36468 24 11.9999 24C18.6352 24 23.9999 18.7765 23.9999 12C24.0281 5.22353 18.6352 0 11.9999 0ZM11.9999 17.7882C8.80937 17.7882 6.23997 15.2753 6.23997 12.0282C6.23997 8.75294 8.80937 6.26824 11.9999 6.26824C15.1905 6.24 17.7882 8.75294 17.7882 12C17.7882 15.2753 15.1905 17.7882 11.9999 17.7882Z"
                fill="#0F1221"
            />
        </svg>
    );
}
