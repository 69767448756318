import axios, { AxiosRequestConfig } from 'axios';
import { showToast } from 'spoton-lib';
import { configureAxiosInstanceForOAuth } from '@spotoninc/okta-refresh-interceptor';

import { getBffBaseUrl, getCoreLoginBaseUrl } from 'features';
import { HTTP_STATUS_CODES } from 'utils/constants';
import { ErrorResult, SuccessResult } from './axios.types';

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

const constructBaseUrl = (url: string) => `${url}/api/v1`;

const createApi = (baseURL?: string) => {
    return axios.create({
        baseURL: baseURL,
        withCredentials: true,
    });
};

export const API = createApi();

export const configureApi = (useEphemeralBaseUrl: boolean) => {
    const BFF_BASE_URL = getBffBaseUrl(useEphemeralBaseUrl);
    const CORE_LOGIN_BASE_URL = getCoreLoginBaseUrl(useEphemeralBaseUrl);

    API.defaults.baseURL = constructBaseUrl(BFF_BASE_URL);
    // API.defaults.baseURL =
    //     'https://doordash-backend.ue1.staging.spoton.sh/api/v1';

    configureAxiosInstanceForOAuth(API, {
        dashboardBaseUrl: window.applicationBase,
    });

    API.interceptors.response.use(
        (response) => response,
        (data) => {
            if (data.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
                const nextUrl = window.location.href;
                window.location.href = `${CORE_LOGIN_BASE_URL}/home?next=${nextUrl}`;
            }
            return Promise.reject(data);
        },
    );
};

export const parseResponse = <T>(
    response: any,
): SuccessResult<T> | ErrorResult => {
    if (response.length === 0) {
        return response;
    }
    const data = JSON.parse(response);
    if (data?.errors) {
        return data;
    }
    return {
        status: 'success',
        data,
    };
};

const request = async <T>(
    config: AxiosRequestConfig,
): Promise<SuccessResult<T> | ErrorResult> => {
    try {
        const response = await API.request<T>({
            ...config,
            transformResponse: (res) => {
                const resp = parseResponse<T>(res);
                return resp.status === 'success' ? resp.data : resp;
            },
        });
        return {
            status: 'success',
            data: response.data,
        };
    } catch (error: any) {
        const statusCode = error.response.status;
        if (
            statusCode !== 440 &&
            statusCode !== HTTP_STATUS_CODES.NOT_FOUND &&
            statusCode !== HTTP_STATUS_CODES.PRECONDITION_FAILED
        ) {
            showToast({
                content: error.response.data.errors,
                containerId: 'toast-container',
                variant: 'danger',
                position: 'top-center',
            });
        }
        return {
            status: 'failure',
            errors: {
                statusCode: error.response.status,
                error: error.response.data.errors,
            },
        };
    }
};

export const AxiosAPI = { request };
