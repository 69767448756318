import React from 'react';
import { Hyperlink, Text } from 'spoton-lib';

import { getConfigVar, ReusableModal } from 'features/common';

interface ISessionExpiredModal {
    isSessionExpired: boolean;
    setIsSessionExpired: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SessionExpiredModal = (props: ISessionExpiredModal) => {
    const { isSessionExpired, setIsSessionExpired } = props;

    const onClickSignIn = () => {
        window.location.href = getConfigVar('REACT_APP_DOORDASH_SIGNIN_URL');
    };

    return (
        <ReusableModal
            isOpen={isSessionExpired}
            title="Session expired"
            onCancel={() => setIsSessionExpired(false)}
            showFooter={false}
        >
            <Text fontSize={16} data-testid="session-expired-info">
                Your session has expired. Please
                <Hyperlink
                    size={16}
                    onClick={onClickSignIn}
                    color="#1C5DCD"
                    data-testid="sign-in-link"
                >
                    click here
                </Hyperlink>
                to Sign in again.
            </Text>
        </ReusableModal>
    );
};
