import React from 'react';
import { Button, Icon, Text } from 'spoton-lib';

import styles from './ExternalLinkButton.module.scss';

interface IExternalLinkButton {
    onClick: () => void;
    linkText: string;
}

export function ExternalLinkButton({ onClick, linkText }: IExternalLinkButton) {
    return (
        <Button className={styles.ExternalLinkButton_button} onClick={onClick}>
            <Text type="sub2">{linkText}</Text>
            <Icon size={24} name="ExternalLinkIcon" />
        </Button>
    );
}
