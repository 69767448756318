export const HTTP_STATUS_CODES = {
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    PRECONDITION_FAILED: 412,
};

export const REQUEST_METHOD = {
    POST: 'POST',
    GET: 'GET',
    DELETE: 'DELETE',
    PUT: 'PUT',
    PATCH: 'PATCH',
};

export const URL = {
    DOORDASH_CONTACT_SUPPORT_URL:
        'https://help.doordash.com/merchants/s/merchant-support?language=en_US',
    DOORDASH_URL: 'https://www.doordash.com/merchant/summary',
    STOREFRONT_URL: 'https://www.order.online/store/',
    MARKETPLACE_URL: 'https://doordash.com/store/',
    BACK_OF_HOUSE_URL: 'https://client.restaurantpos.spoton.com/b/',
    DOORDASH_BOOK_CALL_URL:
        'https://doordash.chilipiper.com/book/sf-integrated-partner',
    DOORDASH_SMART_BUTTON_ARTICLE_URL:
        'https://help.doordash.com/merchants/s/article/How-to-add-the-Storefront-Online-Ordering-Smart-Button-to-your-website?language=en_US',
    DOORDASH_ADD_STOREFRONT_LINK_URL:
        'https://help.doordash.com/merchants/s/article/Activate-Storefront-on-your-website?language=en_US#pub',
    DOORDASH_LEARN_MORE: '#',
    DOORDASH_KNOWLEDGE_CENTER_PAGE:
        'https://help.spoton.com/space/SK/3456696403/DoorDash+Marketplace+Delivery+Price+Markup+%257C+SpotOn+Express',
};

export const ROOT_ELEMENT_ID = 'single-spa-application:@spoton/doordash';
